@import "~antd/dist/antd.min.css";

@font-face {
  font-family: "PingFangSC-Regular";
  src: local("PingFangSC-Regular"), url(./fonts/PingFangSC-Regular.ttf) format("ttf");
}
@font-face {
  font-family: "PingFangSC-Medium";
  src: local("PingFangSC-Medium"), url(./fonts/PingFangSC-Medium.ttf) format("ttf");
}

@font-face {
  font-family: "Arial-BoldMT";
  src: local("Arial-BoldMT"), url(./fonts/Arial-BoldMT.otf) format("otf");
}
@font-face {
  font-family: "DINAlternate-Bold";
  src: local("DINAlternate-Bold"), url(./fonts/DINAlternate-Bold.otf) format("otf");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "PingFangSC-Regular", "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
