.Toastify__toast-container--bottom-center {
  /* 不加 !important，干不过 Toastify 自己的样式配置�� */
  bottom: 10vh !important;
  width: 420px !important;
}

/* .Toastify__toast-theme--light { */
.toast-toast {
  /* 不加 !important，干不过 Toastify 自己的样式配置�� */
  min-height: 42px !important;
  border-radius: 0 !important;
  background-color: #383838 !important;
}

.toast-body {
  padding: 0 40px !important;
  text-align: center;
  font-size: 14px;
  color: white;
}
